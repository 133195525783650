$(() => {
  const questions = $('.booking-questions')

  if (questions.length) {
    questions.find('.js-option').on('click', event => {
      const target = $(event.target);

      const activeQuestion = target.parents('.booking-question');
      const nextQuestion = activeQuestion.next();

      const bookingId = activeQuestion.find('input[name="booking_id"]').val();
      const bookingQuestionId = activeQuestion.find('input[name="booking_question_id"]').val();
      const bookingQuestionTemplateId = activeQuestion.find('input[name="booking_question_template_id"]').val();
      const bookingQuestionTemplateAnswerId = target.data('answer-id');

      let answer = target.data('answer');
      if (target.hasClass('open-question')) {
        answer = activeQuestion.find('textarea#answer').val();
      }

      if (bookingQuestionId) {
        updateQuestionAnswer(bookingId, bookingQuestionId, bookingQuestionTemplateId, bookingQuestionTemplateAnswerId, answer);
      } else {
        addQuestionAnswer(bookingId, bookingQuestionTemplateId, bookingQuestionTemplateAnswerId, answer);
      }

      if (!activeQuestion.is(':last-child')) {
        activeQuestion.removeClass('active').hide();
        nextQuestion.animate({width: 'show', opacity: 1}, 'slow').addClass('active');
      }
    });
  }

  function addQuestionAnswer(bookingId, bookingQuestionTemplateId, bookingQuestionTemplateAnswerId, answer) {
    $.ajax({
      method: 'POST',
      url: '/bookings/' + bookingId + '/questions.json',
      data: {
        booking_id: bookingId,
        booking_questions: {
          booking_question_template_id: bookingQuestionTemplateId,
          booking_question_template_answer_id: bookingQuestionTemplateAnswerId,
          answer: answer
        }
      }
    })
  }

  function updateQuestionAnswer(bookingId, bookingQuestionId, bookingQuestionTemplateId, bookingQuestionTemplateAnswerId, answer) {
    $.ajax({
      method: 'PATCH',
      url: '/bookings/' + bookingId + '/questions/' + bookingQuestionId + '.json',
      data: {
        booking_id: bookingId,
        booking_questions: {
          booking_question_template_id: bookingQuestionTemplateId,
          booking_question_template_answer_id: bookingQuestionTemplateAnswerId,
          answer: answer
        }
      }
    })
  }
});
