import $ from 'jquery'

// Handle slidedowns
const $SlideDowns = {

  slideDownTimeout: [],

  handleClick: function (link, slideDown, wrapper, textToHide) {
    if (typeof wrapper == 'undefined') {
      wrapper = false;
    }
    if (typeof textToHide == 'undefined') {
      textToHide = false;
    }

    if (wrapper) {
      wrapper.on('click', link, function (e) {
        link = $(this);
        slideDown = link.next(slideDown);
        $SlideDowns.click(e, (textToHide ? textToHide : link), slideDown);
      });
    } else {
      link.on('click', function (e) {
        $SlideDowns.click(e, (textToHide ? textToHide : link), slideDown);
      });
    }

  },


  click: function (e, link, slideDown) {
    e.preventDefault();
    e.stopPropagation();

    $SlideDowns.slideDownTimeout[link] = setTimeout(function () {
      $SlideDowns.slideDown(link, slideDown);
    }, 500);
    slideDown.addClass('opening').on('transitionend webkitTransitionEnd', function () {
      $SlideDowns.slideDown(link, slideDown);
    });
    link.addClass('hidden');
  },


  slideDown: function (link, slideDown) {
    clearTimeout($SlideDowns.slideDownTimeout[link]);
    slideDown
        .off('transitionend')
        .off('webkitTransitionEnd')
        .addClass('open');

    $SlideDowns.slideDownTimeout[link] = setTimeout(function () {
      slideDown.removeClass('opening');
    }, 50);

  },

  slideUp: function (link, slideDown) {
    clearTimeout($SlideDowns.slideDownTimeout[link]);

    link.removeClass('hidden');
    slideDown.addClass('opening').removeClass('open');
    $SlideDowns.slideDownTimeout[link] = setTimeout(function () {
      slideDown.removeClass('opening');
    }, 50);
  }

};

export default $SlideDowns;
