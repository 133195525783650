import $ from 'jquery'

$(function () {
  $('.toggle-more').on('click', function (e) {
    var btn;
    e.preventDefault();
    btn = $(e.target);
    if (/show\s+more/i.test(btn.text())) {
      return btn.parents('.landing-page-panel').find('li').fadeIn(300, function () {
        return btn.text('SHOW LESS');
      });
    } else {
      return btn.parents('.landing-page-panel').find('.excess').fadeOut(300, function () {
        return btn.text('SHOW MORE');
      });
    }
  });
  $('.landing-page-pagination .pagination-controls a').on('click', function (e) {
    e.preventDefault();
    var btn = $(e.target);
    var parent = btn.parents('.landing-page-pagination');
    parent.find('.page.active').removeClass('active');
    parent.find('.' + btn.data('page')).addClass('active');
    parent.find('.pagination-controls a.active').removeClass('active');
    btn.addClass('active');
  });

  $('.landing-page-panel h3').on('click', function(e) {
    var landing_page_panel = $(e.target).parents('.landing-page-panel');
    var expand_button = landing_page_panel.find('span.expand');
    var collapse_button = landing_page_panel.find('span.collapse');

    if(!landing_page_panel.hasClass('landing-page-panel-collapsible-test')) return;

    if (expand_button.hasClass('hidden')) {
      expand_button.removeClass('hidden');
      collapse_button.addClass('hidden');

      landing_page_panel.find('div, ul').addClass('collapsed');
    } else {
      expand_button.addClass('hidden');
      collapse_button.removeClass('hidden');

      landing_page_panel.find('.collapsed').removeClass('collapsed');
    }
  })

  $('.landing-mechanic .comment').each((index, element) => {
    const comment = $(element).find('.comment-text')[0];
    // comment has overflow and truncated
    if (comment.offsetHeight < comment.scrollHeight ||
      comment.offsetWidth < comment.scrollWidth) {
      $(element).find('.comment-show-rest').removeClass('hidden');
    }

    $(element).find('.comment-show-rest').click(() => {
      $(comment).removeClass('truncate');
    })
  })
});
