import $ from 'jquery'

$(function () {

  if ($('.diagnostic-tool-v2').length) {

    function add_answered_question(question, answer) {
      var listElement = $("<li/>", {
        'class': 'answered-question',
        'data-question-id': question.attr('id')
      }).addClass('answered-question');
      listElement.appendTo('#answered-questions');
      var questionText = question.find('.diagnostic-question-text').text();
      $("<span/>").text(questionText).appendTo(listElement);
      $("<span/>").addClass('answer-text').text(answer.text()).appendTo(listElement);
      $("<a/>", {
        'class': 'go-back',
        'text': 'back'
      }).appendTo(listElement);
    }

    function track_clicked_answer(answer) {
      var node = $('.diagnostic-node').not(':hidden');
      var question_text = node.find('.diagnostic-question-text').text();
      var answer_text;
      if (answer.hasClass('image-answer')) {
        answer_text = answer.find('img').attr('alt');
      } else {
        answer_text = answer.text().trim();
      }
      _kmq.push(['record', 'Diagnostic tool answered question', {question: question_text, answer: answer_text}]);
    }

    $('.diagnostic-answer').on('click', function (e) {
      var answer = $(e.currentTarget);
      track_clicked_answer(answer);
      var question = answer.parent('.diagnostic-node');
      question.addClass('hidden');
      $(answer.data('target')).removeClass('hidden');
      add_answered_question(question, answer);
    });

    $('#answered-questions').on('click', '.go-back', function (e) {
      var answeredQuestion = $(e.currentTarget).parent('.answered-question');
      answeredQuestion.nextAll('.answered-question').remove();
      $('.diagnostic-node').addClass('hidden');
      $('#' + answeredQuestion.data('questionId')).removeClass('hidden');
      answeredQuestion.remove();
    });

    $('.js-dt-mir').on('click', function () {
      _kmq.push(['record', 'Diagnostic tool MiR hit']);
    });

    $('.js-dt-get-quote').on('click', function (e) {
      _kmq.push(['record', 'Diagnostic tool get quote', {description: $(e.target).parents('.panel-body').find('h5').text()}]);
    });

    $('.js-dt-side-bar-diagnostic').on('click', function () {
      _kmq.push(['record', 'Diagnostic tool diagnostic sidebar hit']);
    });

    $('.js-dt-side-bar-mir').on('click', function () {
      _kmq.push(['record', 'Diagnostic tool MiR sidebar hit']);
    });
  }
});
