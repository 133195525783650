import $ from 'jquery'
import $SlideDowns from './slidedowns'

// Handle promo code forms
const $PromoCodes = {

  handleForm: function () {

    var body = $('body'),
        isDynamicQuotePage = typeof body.attr('id') != 'undefined' && body.attr('id') == 'get-quote';

    // Handle a successful promo code being added
    var promoCodeForm = $('form.js-promotion-code-form');
    promoCodeForm.on('success', function (e, data) {
      var $this = $(this);
      if (isDynamicQuotePage) {
        $PromoCodes.updateDynamicQuotePage(data);
        $SlideDowns.slideUp($this.siblings('.show-promo-code').find('a'), $this);
      } else {
        $PromoCodes.updateRegularQuotePage(data);
        $('.promotion-code-show').removeClass('hidden');
        promoCodeForm.addClass('hidden');
      }
    });
    promoCodeForm.on('not_accepted', function (e, data) {
      if (isDynamicQuotePage) {
        $PromoCodes.updateDynamicQuotePage(data);
        $('dl.totals').children('.promotion').remove();
        $('.js-basket .totals .promotion').addClass('hidden');
      } else {
        $PromoCodes.updateRegularQuotePage(data);
        $('div.quote-totals').find('.promotion-details').remove();
      }
    });
  },

  updateRegularQuotePage: function (data) {
    var totals = $('div.quote-totals'),
        promoArea = totals.children('p.promotion-details'),
        content = data['success'] + ' <span class="right">£' + data['discount'] + '</span>',
        totalPrice = totals.find('p.our-price .total'),
        savingPercentage = totals.find('span.saving'),
        promotionBox = $('div.promotion-savings-box'),
        totalPriceNew = $('div.quote-totals h2')

    if (promoArea.length) {
      promoArea.html(content);
    } else {
      promotionBox.append('<p class="promotion-details">' + content + '</p>')
      totalPriceNew.text('£' + data['new_price']);
    }

    totalPrice.text('£' + data['new_price']);
    if (savingPercentage) {
      var dealerPrice = totals.find('.franchise-estimate .total'),
          dealerPriceValue = dealerPrice.text().replace('£', '');
      savingPercentage.text(Math.floor(100 * (dealerPriceValue - data['new_price']) / dealerPriceValue));
    }
  },

  updateDynamicQuotePage: function (data) {
    var basket = $('.js-basket'),
        totals = basket.find('.totals'),
        totalPrice = totals.find('.our-price').children('span'),
        discount = '-£' + (data['discount'] ? data['discount'] : data['promotional_saving']);

    window.Application.eachBasketRenderer(function (renderer) {
      renderer.promoDiscount(discount);
    });

    totalPrice.text(data['new_price']);

    function updateSavings(container, dealerPriceKey, saving) {
        const savingPercentage = container.find('.savings'),
          savingPercentageValue = savingPercentage.children('span'),
          dealerPrice = container.find('.dealer-price');

          if (saving) {
        savingPercentageValue.text(saving);
      } else {
        var dealerPriceEstimate = dealerPrice.children('span').data(dealerPriceKey);
        if (dealerPrice.not('.hidden').length && dealerPriceEstimate) {
          const newSaving = (dealerPriceEstimate - data['new_price']) / dealerPriceEstimate;
          savingPercentageValue.text(Math.floor(newSaving * 100));
        } else if (data['discount']) {
          savingPercentage.removeClass('hidden');
          const beforePrice = Number(data['new_price']) + Number(data['discount']),
            newSaving = (beforePrice - data['new_price']) / beforePrice;
          savingPercentageValue.text(Math.round(100 * newSaving));
        } else {
          savingPercentage.addClass('hidden');
        }
      }
    }

    updateSavings(totals, 'dealership-price', data['saving']);

    totals.trigger('updateFullBasket');
  }

};

export default $PromoCodes;
