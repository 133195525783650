import $ from 'jquery'
import $Scroll from './smooth-scrolling'
import $Form from './forms'

!function ($) {
  $.fn.dateTimePicker = function (options, componentOptions) {
    return Promise.all([
      import('CMDateTimePicker'),
      import('moment'),
    ]).then(([{default: dtp}, {default: moment}]) => {
      moment.tz.setDefault("Europe/London");
      // assign default functions if none are passed in
      const settings = $.extend({
        isLimitedAvailability: isLimitedAvailability
      }, componentOptions);

      const form = $(this).parents('form'),
        questionContainer = $(this).parents('.question'),
        limitedAvailabilityWarning = questionContainer.find('.limited-availability-warning');

      function isLimitedAvailability(date) {
        const today = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);
        return date.isSame(today, 'day') || date.weekday() === 0 || (date.isSame(tomorrow, 'day') && today.getHours() >= 22);
      }

      function limitedAvailabilitySelection() {
        const dates = questionContainer.find('input[name="' + options.inputName + '"]:checked').toArray().map(checkbox => {
          return moment(parseInt(checkbox.value.split('-')[0] * 1000));
        });
        return dates.length > 0 && dates.filter(date => !settings.isLimitedAvailability(date)).length === 0;
      }

      function updateLimitedAvailabilityNotice() {
        (limitedAvailabilityWarning.length && limitedAvailabilitySelection()) ?
          limitedAvailabilityWarning.removeClass('hidden') : limitedAvailabilityWarning.addClass('hidden').removeClass('limited-availability-error');
      }

      function clearInputErrors() {
        questionContainer.find('.error').remove();
      }

      function addCheckbox(value, checked = true) {
        return $('<input />', {
          type: 'checkbox',
          name: options.inputName,
          value: value,
          class: 'hidden',
          checked: checked
        }).appendTo(options.inputsContainer);
      }

      function slotDisabled(start, end) {
        // time slots are disabled if they are outside the date time picker range
        if (end.isSameOrBefore(moment(settings.startDate)) || start.isSameOrAfter(moment(settings.endDate))) {
          return true;
        }

        return (settings.disabledTimeSlots || []).some(slot => {
          const [startTime, endTime] = slot[1].split('-').map(time => moment(slot[0] + " " + time));
          return end.isAfter(startTime) && start.isBefore(endTime)
        });
      }

      // mark any available time slots as selected if they are completely covered by a selected time slot window (requested_arrival_window on the booking)
      function addInitialCheckboxes() {
        settings.selectedTimeSlots.forEach(timeSlot => {
          const [startTime, endTime] = timeSlot[1].split('-').map(time => moment(timeSlot[0] + " " + time));
          settings.defaultTimeSlots.forEach(defaultTimeSlot => {
            const [slotStartTime, slotEndTime] = defaultTimeSlot.split('-').map(time => moment(timeSlot[0] + " " + time));
            if (slotStartTime.isAfter() && !slotDisabled(slotStartTime, slotEndTime) && slotStartTime.isSameOrAfter(startTime) && slotEndTime.isSameOrBefore(endTime)) {
              addCheckbox(slotStartTime.unix() + "-" + slotEndTime.unix());
            }
          });
        });
      }

      function onSelectionChanged(timeSlot) {
        clearInputErrors();
        const timeSlotValue = timeSlot.start.unix() + "-" + timeSlot.end.unix();
        if (timeSlot.selected) {
          addCheckbox(timeSlotValue);
        } else {
          $(options.inputsContainer).find('input[value=' + timeSlotValue + ']').remove();
        }
        updateLimitedAvailabilityNotice();
      }

      function onFormSubmit() {
        if ($(this).length === 0 || $(this).is(':hidden')) {
          return false
        }

        if (limitedAvailabilitySelection()) {
          limitedAvailabilityWarning.addClass('limited-availability-error');
          $Scroll.smoothScrollBottom(limitedAvailabilityWarning);
          $Form.renableButton(form.find('input[type=submit]'));
          return false;
        } else {
          $Form.postAjax(form, form.find('input[type=submit]'));
          return true;
        }
      }

      form.on('submit', e => {
        e.preventDefault();
        e.stopPropagation();

        return onFormSubmit.bind(this)();
      });

      addInitialCheckboxes();
      updateLimitedAvailabilityNotice();
      const picker = dtp.new(settings);
      picker.onSelectionChange(onSelectionChanged);
      picker.render('#' + $(this).attr('id'));
      return picker;
    })
  };
}($);
