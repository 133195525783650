import $ from 'jquery'

$(function () {
  var updateText;
  updateText = function (event) {
    var input;
    input = $(this);
    return setTimeout((function () {
      var val;
      val = input.val();
      if (val !== '') {
        return input.parent().addClass('floating-placeholder-float');
      } else {
        return input.parent().removeClass('floating-placeholder-float');
      }
    }), 1);
  };
  $('input, textarea').on('keydown', updateText);
  $('input, textarea').on('change', updateText);
});
