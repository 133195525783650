window.initZendesk = () => {
  if (typeof zE !== "undefined") {
    const launcher = $('#zendesk-launcher');
    const unreadIndicator = $('#unread-indicator');

    const populateUnreadIndicator = (count) => {
      if (!count) return resetUnreadIndicator();

      unreadIndicator.removeClass('hidden');
      unreadIndicator.html(count);
    };

    const resetUnreadIndicator = () => {
      unreadIndicator.addClass('hidden');
      unreadIndicator.html(0)
    };

    zE('messenger:on', 'unreadMessages', (count) => {
      populateUnreadIndicator(count);
    });

    // on page load always close widget
    zE('messenger', 'close');

    launcher.on('click', () => {
      zE('messenger', 'open');
      const expiry = (new Date(Date.now() + 7200 * 1000)).toUTCString(); // 2 hours
      document.cookie = "chat_widget_recently_opened= true; expires=" + expiry + "; path=/";
      resetUnreadIndicator();
    });
  }
}
