import $ from 'jquery'

$(function () {
  var form = $('#new_careers_email_form');
  if (form.length) {
    // HACK! This hack prevents the front end validation framework from submitting
    // the form after running the validations. The form submit is therefore
    // handled by the default Rails-ujs.
    form.on('submit', function (e) {
    });
  }
});
