import $ from 'jquery'
import $SlideDowns from './slidedowns'

$(function () {
  var body = $('body'),
      isDynamicQuotePage = typeof body.attr('id') != 'undefined' && body.attr('id') == 'get-quote';
  if (isDynamicQuotePage) {
    var sidebarShowPromo = $('.booking-side-panel').find('p.show-promo-code'),
        fullBasketShowPromo = $('.js-basket-body').find('p.show-promo-code');

    var sidebarForm = sidebarShowPromo.siblings('.js-promotion-code-form'),
        fullBasketForm = fullBasketShowPromo.siblings('.js-promotion-code-form'),
        sidebarLink = sidebarShowPromo.children('a'),
        fullBasketLink = fullBasketShowPromo.children('a');

    $SlideDowns.handleClick(sidebarShowPromo, sidebarForm, false, sidebarLink);
    $SlideDowns.handleClick('.show-promo-code', '.js-promotion-code-form', $('.js-basket-mobile-popup'), fullBasketLink);
  }
});
