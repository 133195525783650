import $ from 'jquery'

$(function () {

  var carousel, sizeCarouselToTallestItem;
  sizeCarouselToTallestItem = function (carousel_elem) {
    var heights;
    heights = carousel.find(".item").map(function () {
      return $(this).height();
    });
    return carousel.find(".carousel-inner").height(Math.max.apply(null, heights));
  };
  carousel = $("#latestReviewsCarousel");
  if (carousel.length) {
    carousel.carousel();
    sizeCarouselToTallestItem();
    $(window).on('resize', sizeCarouselToTallestItem);
  }

  window.Application.scrollFocus = function (jqueryElement, duration, easing, callback) {
    var elemTop;
    elemTop = jqueryElement.offset().top - 16 - 56;
    if (elemTop < $(window).scrollTop()) {
      return $('html, body').animate({
        scrollTop: elemTop
      }, duration, easing, function () {
        if (jqueryElement.attr('id') !== 'booking-work-date') {
          jqueryElement.focus();
        }
        if (callback) {
          return callback();
        }
      });
    } else {
      if (jqueryElement.attr('id') !== 'booking-work-date') {
        jqueryElement.focus();
      }
      if (callback) {
        return callback();
      }
    }
  };
  $(".newsletter-signup").find("form").on('submit', function () {
    return $(this).find("input[type=submit]").val("Thanks!");
  });
  $("#other-work-toggle").on('click', function (event) {
    $(this).removeClass("hidden-phone");
    $("#popular-work-toggle").removeClass("hidden-phone");
    $("#other-work").slideDown();
    $("#popular-work").slideUp();
    event.preventDefault();
    if (typeof _kmq !== 'undefined') {
      return _kmq.push(['record', 'Landing form - Toggle - Other work', {}]);
    }
  });
  $("#popular-work-toggle").on('click', function (event) {
    $("#popular-work").slideDown();
    $("#other-work").slideUp();
    event.preventDefault();
    if (typeof _kmq !== 'undefined') {
      return _kmq.push(['record', 'Landing form - Toggle - Popular work', {}]);
    }
  });
  if ($('.how-it-works-content').length) {
    $(document).on('click', 'a[data-toggle="destination-tab"]', function (event) {
      var hash;
      event.preventDefault();
      hash = this.href.replace(/^.+#/, '#');
      return $('a[href="' + hash + '"][data-toggle=tab]:first').trigger('click');
    });
  }
  return $('.animate-anchor').on('click', function (event) {
    var anchor;
    event.preventDefault();
    anchor = $(event.target).attr('href');
    return $('html, body').animate({
      scrollTop: $(anchor).offset().top - 50
    }, 1000, function () {
      return window.location.hash = anchor;
    });
  });
});
