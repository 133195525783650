import $ from 'jquery'

$(function () {

  // Reviews page
  var carouselWrapper = $('div#carousel');
  if (carouselWrapper.length) {


    // Stop the page trying to horizontally jump to the carousel content
    $(window).scrollTop();

    // Expand chosen testimonial in the URL
    const carousel = carouselWrapper.children('ul.carousel-viewport');
    const customer = new URLSearchParams(window.location.search).get('testimonial');
    const testimonial = carousel.children('li#' + customer);
    testimonial.find('div.full-interview').addClass('show-full');


    // Handle arrows
    const arrows = carouselWrapper.children('ul.arrows');
    arrows.on('click', 'a', function (e) {
      e.preventDefault();
      e.stopPropagation();
      var li = $(this).parent('li'),
          selectedLI = carousel.children('li.selected'),
          selectedLIPosition = selectedLI.index(),
          selectedLIClass = '',
          newSelectedLI = '',
          newSelectedLIClass = '';


      // Get the next/last, or jump to the beginning/end if we've run out of items
      if (li.hasClass('next')) {
        newSelectedLI = (selectedLIPosition === (carousel.children('li').length - 1) ? carousel.children('li:first') : selectedLI.next());
        selectedLIClass = 'offscreen-left';
        newSelectedLIClass = 'offscreen-right';
      } else {
        newSelectedLI = (selectedLIPosition === 0 ? carousel.children('li:last') : selectedLI.prev());
        selectedLIClass = 'offscreen-right';
        newSelectedLIClass = 'offscreen-left';
      }


      //  Handle old LI
      var fallbackTimeout = null;


      // Reset the unselected LI
      function unselectLI() {
        clearTimeout(fallbackTimeout);

        selectedLI
            .off('transitionend')
            .off('webkitTransitionEnd')
            .addClass('no-animate');

        setTimeout(function () {
          selectedLI
              .removeClass('selected')
              .removeClass(selectedLIClass)
              .find('div.full-interview')
              .removeClass('show-full')
              .removeClass('open')
              .next('p.more')
              .removeClass('hidden');
        }, 20);
      }


      // Move the old LI out the way, then remove its selected class
      fallbackTimeout = setTimeout(unselectLI, 800);
      selectedLI
          .addClass(selectedLIClass)
          .on('transitionend webkitTransitionEnd', unselectLI);


      // Handle new LI
      // Stop the new LI from animating when we move it into position
      newSelectedLI.addClass('no-animate');


      // Call the new LI functions on a timeout, so no-animate has had time to be added
      setTimeout(function () {
        // Overwrite any other classes that might still be on the LI
        newSelectedLI.attr('class', 'no-animate ' + newSelectedLIClass);
        setTimeout(function () {

          // Remove no-animate class
          newSelectedLI.removeClass('no-animate');

          // Change the selected LI
          setTimeout(function () {
            newSelectedLI.removeClass(newSelectedLIClass).addClass('selected');
          }, 20);

        }, 20);
      }, 50);
    });


    // Handle read more
    carousel.on('click', '.more', function (e) {
      e.preventDefault();
      e.stopPropagation();

      var more = $(this),
          interview = more.prev('div.full-interview'),
          fallbackTimeout = null;

      // Add a class to remove max-height from the div all together
      function removeMaxHeight() {
        clearTimeout(fallbackTimeout);
        interview.off('transitionend').off('webkitTransitionEnd').addClass('show-full');
      }

      fallbackTimeout = setTimeout(removeMaxHeight, 800);
      interview.addClass('open').on('transitionend webkitTransitionEnd', removeMaxHeight);
    });
  }
});
