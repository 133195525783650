import $ from 'jquery'

$(function () {
  const fancyboxLinks = $('.fancybox-link');
  if (fancyboxLinks.length > 0) {
    import('jquery.fancybox-plus').then(() => {
      fancyboxLinks.fancyboxPlus({
        'autoDimensions': true,
        'autoScale': true,
        'changeFade': 0,
        'changeSpeed': 0,
        'padding': 0,
        'speedIn': 0,
        'speedOut': 0,
        'transitionIn': 0,
        'transitionOut': 0,
        'titlePosition': 'inside'
      });
    })
  }

  //   Placeholder shim for older browsers
  $('input, textarea').placeholder();
  $("label").on('click', function (event) {
    if ((event.currentTarget === this) && (event.target.nodeName !== 'INPUT')) {
      return $(this).find("input").trigger('click');
    }
  });

  //  Track form usage in Google Analytics
  $(':input').on('blur', function (event) {
    var currentPage;
    currentPage = window.location.pathname;
    if ($(this).val().length > 0) {
      if (typeof gtag !== 'undefined') {
        return gtag('event', 'completed', {
          'event_category': 'Form: ' + currentPage,
          'event_label': $(this).attr('name')
        })
      }
    } else {
      if (typeof gtag !== 'undefined') {
        return gtag('event', 'skipped', {
          'event_category': 'Form: ' + currentPage,
          'event_label': $(this).attr('name')
        })
      }
    }
  });
});
