import $ from 'jquery'
import $Form from './forms'

$(function () {

  if ($('body').is('.contract-unsigned')) {
    var form = $('.js-resend-email-form'),
        form_submit_btn = form.find("input[type='submit']"),
        loading_spinner = $('.loader');

    form.on('submit', function (e) {
      e.preventDefault();
      $Form.disableButton(form_submit_btn);
      loading_spinner.removeClass('hidden');
      $('#resend-success, #resend-failure').addClass('hidden');
      $.ajax({
        type: "POST",
        url: this.action,
        success: resend_success,
        error: resend_error,
        complete: hide_loading_spinner
      });
    });

    function resend_success(text) {
      $('#resend-success').removeClass('hidden');
      $('#resend-success p').text(text);
    }

    function resend_error() {
      $('#resend-failure').removeClass('hidden');
      $Form.renableButton(form_submit_btn);
    }

    function hide_loading_spinner() {
      loading_spinner.addClass('hidden');
    }

  }

  onGoogleMapsReady.then(function () {
    var $map, init_mechanic_map, resized;
    $map = $("#mechanic-area-map");
    resized = true;
    init_mechanic_map = function () {
      var center, circle, map, map_data, map_options;
      map_options = {
        zoom: 11,
        scrollwheel: false,
        streetViewControl: false
      };
      map_data = $map.data();
      if (map_data.circleLat && map_data.circleLng && map_data.circleRadius) {
        center = new google.maps.LatLng(map_data.circleLat, map_data.circleLng);
        map_options.center = center;
        circle = new google.maps.Circle({
          strokeColor: '#38A6EC',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#38A6EC',
          fillOpacity: 0.4,
          center: center,
          radius: map_data.circleRadius
        });
      }
      map = new google.maps.Map($map[0], map_options);
      if (circle) {
        circle.setMap(map);
        map.fitBounds(circle.getBounds());
      }
      return google.maps.event.addDomListener(window, "resize", function () {
        center = map.getCenter();
        google.maps.event.trigger(map, "resize");
        map.setCenter(center);
        return resized = true;
      });
    };
    if ($map.closest(".tab-pane").hasClass("active")) {
      init_mechanic_map();
    } else {
      $('a[href="#area"]').closest('li').on("click", function () {
        if (resized) {
          init_mechanic_map();
          return resized = false;
        }
      });
    }
  });
  
  $(".comment-show-rest").on("click", function (event) {
    var $comment, $head, $target, content;
    event.preventDefault();
    $target = $(event.target);
    $comment = $target.closest(".comment");
    $target.remove();
    content = $comment.find(".rest").html();
    $comment.height($comment.height());
    $head = $comment.find(".head");
    $head.html($head.html() + content);
    return $comment.animate({
      height: $head.height() + 4
    }, "slow", function () {
      $comment.html($head.html());
      return $comment.height('');
    });
  });
  $('.mechanic-certifications-wrapper').on('change', '.mechanic-certification-main', function (event) {
    var main_field, name, other_field;
    main_field = $(this);
    other_field = main_field.closest('.nested-fields').find('.mechanic-certification-other');
    name = main_field.find('option:selected').text();
    if (name.match(/^Other|Training/i)) {
      return other_field.show();
    } else {
      return other_field.hide();
    }
  }).on("click", ".add_fields", function (event) {
    var $link;
    $link = $(this).text("Add another");
    return $link.closest(".mechanic-certifications-wrapper").addClass("nested");
  }).on("click", ".remove_fields", function () {
    var $wrapper;
    $wrapper = $(this).closest(".mechanic-certifications-wrapper");
    if ($wrapper.find(".nested-fields").length <= 1) {
      $wrapper.find(".add_fields").text("Add one now");
      return $wrapper.removeClass("nested");
    }
  });
  $(".click-highlight").on("click", function () {
    var doc, range, selection;
    doc = document;
    if (doc.body.createTextRange) {
      range = doc.body.createTextRange();
      range.moveToElementText(this);
      return range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = doc.createRange();
      range.selectNodeContents(this);
      selection.removeAllRanges();
      return selection.addRange(range);
    }
  });
  const handle_no_personal_url_checkbox = function ($checkbox, $url_input) {
    var current_val;
    if ($checkbox.prop('checked')) {
      current_val = $url_input.val();
      $url_input.data('value-before-disabled', current_val);
      $url_input.val($checkbox.data('no-personal-url-value'));
      $url_input.prop("disabled", true);
      return window.Application.clearError($url_input);
    } else {
      $url_input.prop("disabled", false);
      return $url_input.val($url_input.data('value-before-disabled'));
    }
  };
  $("#mechanic_application_form").on("change", "#mechanic_application_no_personal_url", function (event) {
    var checkbox, url_input;
    checkbox = $(event.currentTarget);
    url_input = $("#mechanic_application_personal_url");
    return handle_no_personal_url_checkbox(checkbox, url_input);
  });
  $("#mechanic_missing_information_form").on("change", "#mechanic_no_personal_url", function (event) {
    var checkbox, url_input;
    checkbox = $(event.currentTarget);
    url_input = $("#mechanic_personal_url");
    return handle_no_personal_url_checkbox(checkbox, url_input);
  });
  return $('.accept-guidelines').on('click', function (e) {
    $('.guidelines-agreement').addClass('hidden');
    $('.sidebar-guidelines').removeClass('hidden');
    return $('.response-container').removeClass('hidden');
  });
});
