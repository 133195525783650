// British English
var enGB = {

  // Common
  'thousandsSeparator': ',',


  // Errors
  'Something went wrong': 'Uh-oh! Something went wrong, there.',

  'Please enter your details': 'Please enter your [$1].',
  'Please select a value': 'Please select a [$1].',

  'Value too short': 'That\'s a bit short! Please enter at least [$1] characters.',
  'Value too long': 'That\'s a bit long! Please enter no more than [$1] characters.',

  'Number too small': 'That\'s a bit low! The number can\'t be less than [$1].',
  'Number too big': 'That\'s a bit much! Please enter a number smaller than [$1].',

  'Invalid email address': 'Oops! Your email address doesn\'t look quite right. Please enter it in the format [strong]yourname@youraddress.com[/strong].',

  'Phone number should only use digits': 'Your contact number doesn\'t look right - please use digits, e.g. 020 3322 9988.',
  'Phone number too short': 'Your contact number doesn\'t look quite right. It should be at least ten digits, e.g. 020 3322 9988.',

  'Password too short': 'Your password should be at least [strong]6 characters[/strong] long.',
  'Password too long': 'Your password shouldn\'t be more than [strong]128 characters[/strong] long - think of all the typing you\'d have to do!',
  'Passwords don\'t match': 'Oops! The passwords don\'t match. Please try again.',

  'Please enter digits only': 'Please use digits (0 - 9) here.',

  'Credit card should only use digits': 'Your card number doesn\'t look quite right - please use digits (0 - 9) only.',
  'Credit card invalid': 'Your card number doesn\'t look quite right. Please check you\'ve entered it correctly.',
  'CVC invalid': 'Your security code doesn\'t look right; please try entering it again.',
  'Invalid date': 'Oops! That date\'s invalid! Please change it.',

  'No secure connection': 'A secure connection could not be made. Please check your internet connection or try again later.',

  'Please choose at least one option': 'Please choose at least one option, or tick \'other\' if the way you heard about us isn\'t on the list.',

  'Please specify': 'Please specify where you heard about us, using the textbox.',
  'What was the other website you heard about us from?': 'What was the other website you heard about ClickMechanic from?',
  'Please tell us where the other place you heard about us was': 'Please tell us where the other place you heard about ClickMechanic was.',


  // Car form
  'Enter your car registration': 'Enter your registration number, or [link="/bookings/car?fields=true"]enter your car details if you\'re unsure[/link].',
  'Please enter your full postcode': 'Please enter your UK postcode in full, e.g. W12 7SB.',
  'Sorry - we\'re not in that area yet': 'Sorry - we\'re not in [$1] yet.',
  'We couldn\'t look-up your vehicle automatically': 'We can\'t look-up your vehicle. Please [link="/bookings/car?fields=true"]tell us your car\'s make and model[/link] to get an accurate quote.',


  // How did you hear about us
  'Thanks for letting us know!': 'Thanks for letting us know!',


  // Contact us page
  'Contact message sent': 'Your message has been sent',
  'Thank you for contacting us': 'Thank you for getting in touch. We\'ll get back to you as quickly as we can.',


  // Fleet servicing
  'Thanks!': 'Thanks!',
  'We\'ll be in touch': 'We\'ll get in touch soon to learn more about your requirements and answer any questions you have.',


  // Bookings - dynamic quotes
  'We can\'t give you an instant quote': 'Sorry, we can\'t give you an instant quote',
  'We can only quote for some work': 'We can only quote for some work. Read more',
  'Request a quote to get a detailed breakdown': 'Please [link="[$1]" target="_blank"]request a quote[/link], and we\'ll get back to you as soon as we can with a detailed quote.',
  'Request a quote': 'Request a quote',
  'Quote doesn\'t include some items': 'We couldn\'t get an instant quote for:',
  'Request a quote to get your full quote': 'We\'ve left that work off your quote. If you\'d like a quote which includes this, [link="[$1]" target="_blank"]get in touch[/link] and we\'ll get one together for you as soon as we can.',
  'What happens?': 'What happens?',
  'see details': 'see details',
  'If you\'re not sure what the problem is...': 'if you\'re not sure what the problem is or exactly which part needs to be replaced, we recommend booking a mechanic to inspect your vehicle first.',

  // Promo codes
  'Promo code applied': 'Promo code applied:'

};

export default enGB;
