import $ from 'jquery'

$(function () {

  /* register/login modal */
  $(".js-sign-in-toggle").on('click', function () {
    $(".sign-up-wrapper").addClass("is-hidden");
    $(".sign-in-wrapper").removeClass("is-hidden");
    return false;
  });
  $(".js-register-toggle").on('click', function (e) {
    $(".sign-in-wrapper").addClass("is-hidden");
    $(".sign-up-wrapper").removeClass("is-hidden");
    return false;
  });
  return $('.js-sign-up-form-toggle').on('click', function (e) {
    $(e.currentTarget).fadeOut(function () {
      $(e.currentTarget).addClass('hidden');
      return $('.sign-up-form-container').fadeIn();
    });
    return false;
  });
});
