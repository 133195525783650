import $ from 'jquery'

// Handle smooth scrolling
const $Scroll = (function () {
  function scrollTo(position, duration, callback) {
    var animationsFinished = 0;

    if (typeof duration == 'undefined') {
      duration = Math.pow(Math.abs(window.scrollY - position), 2 / 3) * 5;
    }

    $('html, body').stop().animate({scrollTop: position}, duration, function () {
      animationsFinished++;

      if (animationsFinished <= 1) {
        return false;
      }
      if (typeof callback == 'function') {
        callback();
      }
    });
  }

  function smoothScroll(element, duration, callback) {
    var position = Math.round(element.offset().top - 150);
    scrollTo(position, duration, callback);
  }

  function smoothScrollBottom(element, duration, callback) {
    var bottomPosition = Math.round(element.offset().top + element.height() + 40);
    var position = bottomPosition - $(window).height();
    position = position > 0 ? position : 0;
    scrollTo(position, duration, callback)
  }

  return {
    smoothScroll: smoothScroll,
    smoothScrollBottom: smoothScrollBottom,
  }
})();

export default $Scroll;



