import $ from 'jquery'

$(function () {
  $(".mobile-site-navigation-launcher").on('click', function (event) {
    var body, closeNavigation;
    event.preventDefault();
    event.stopPropagation();
    body = $("body");

    // Close the nav
    closeNavigation = function () {
      body.removeClass('mobile-nav-show');
      return body.off('click.mobile-navigation');
    };

    // If the nav is open, close it
    if (body.hasClass('mobile-nav-show')) {
      return closeNavigation();
    }

    // Else, open it
    else {

      // Prevent user from scrolling by setting overflow hidden
      body.addClass('mobile-nav-show');
      return body.on('click.mobile-navigation', function (e) {
        var nav;
        if (e.originalEvent === event.originalEvent) {
          return;
        }
        nav = $('.mobile-site-navigation');
        if (!nav.is(e.target) && nav.has(e.target).length === 0) {
          return closeNavigation();
        }
      });
    }
  });

  $(".main-footer h3").on('click', function (event) {
    var $links, $this;
    $links = $(".main-footer .link-group ul");
    $this = $(this);

    // Hide all open links
    $links.slideUp();
    // Checks if links are showing. User closes them if they are.
    if ($this.next($links).is(":visible")) {
      return $(".link-group ul").slideUp();
    }
    // Show the links if they're not visible.
    else {
      return $this.next($links).slideDown();
    }
  });

  const nav = $('.primary-site-navigation:not(.static-nav)');
  /* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */
  if (nav.length > 0) {
    let prevScrollPos = window.pageYOffset;
    if (prevScrollPos > 0) {
      nav.addClass('show-nav');
    }
    window.onscroll = () => {
      const currentScrollPos = Math.max(window.pageYOffset, 0);
      if (currentScrollPos === 0) {
        nav.removeClass('show-nav').removeClass('hide-nav');
      } else if (prevScrollPos > currentScrollPos) {
        nav.addClass('show-nav').removeClass('hide-nav');
      } else if (prevScrollPos < currentScrollPos) {
        nav.addClass('hide-nav').removeClass('show-nav');
      }
      prevScrollPos = currentScrollPos;
    }
  }

  return $('.booking-guidance-question').on('click', function (event) {
    var answer, question;
    question = $(this);
    answer = question.next(".booking-guidance-answer");
    answer.slideToggle();
    question.toggleClass('expanded');
    return false;
  });
});
