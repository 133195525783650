import $ from 'jquery'

$(function () {
  return $('.inline-tab-link, .tabbed-nav li, .responsive-tabbed-nav li').on('click', function (e) {
    e.preventDefault();
    var tab_to_open = $(e.delegateTarget).find('a').attr('href');
    var container = $(e.delegateTarget).closest('.tabbed-nav-container');
    if (container.length) {
      container.find('.tab-pane.active').removeClass('active');
      container.find('.nav-tabs li').removeClass('active');
      container.find(tab_to_open).addClass('active');
    } else {
      $('.tab-pane.active').removeClass('active');
      $('.nav-tabs li').removeClass('active');
      $(tab_to_open).addClass('active');
    }
    $("a[href='" + tab_to_open + "']").parents('li').addClass('active');
  });
});
