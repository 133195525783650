import $ from 'jquery'

$(function () {
  let resizeListeners = [];
  const noticeContainer = $('.cookie-consent-notice-container');
  const bookingFloatingFooter = $('.v2-booking-floating-footer')

  noticeContainer.on('click', '.accept-btn', function (e) {
    recreateScripts();
    $(e.delegateTarget).hide();
    resizeListeners.forEach(fn => fn());
  });

  $(window).on('resize', function () {
    resizeListeners.forEach(fn => fn());
  });

  function recreateScripts() {
    $("script[type='text/plain']").each(function (i) {
      const element = $(this);
      const scriptContent = element.text();

      let newScript = $('<script>').attr('type', 'text/javascript').text(scriptContent);
      element.after(newScript);
      element.remove();
    })
  }

  function noticeHeight() {
    return noticeContainer.is(':visible') ? noticeContainer.outerHeight() : 0;
  }

  function noticeWithBookingFloatingFooterHeight() {
    return bookingFloatingFooter.is(':visible') ? noticeHeight() + bookingFloatingFooter.height() : noticeHeight()
  }

  if (typeof zE !== 'undefined') {
    var adjustZendeskLauncher = () => {
      const height = noticeWithBookingFloatingFooterHeight();
      $('#zendesk-launcher').css('bottom', (height + 20) + 'px')
    };

    const adjustZendeskWhenExists = () => {
      if (noticeContainer.is(':visible') || bookingFloatingFooter.is(':visible')) {
        const checkExist = setInterval(() => {
          if ($('#zendesk-launcher').is(':visible')) {
            adjustZendeskLauncher()
            clearInterval(checkExist)
          }
        }, 100)
      }
    }

    resizeListeners.push(adjustZendeskLauncher);
    adjustZendeskWhenExists()
  }

  const workPage = $('.work-page');
  if (workPage.length) {
    function adjustBookingFloatingFooter() {
      bookingFloatingFooter.attr('style', 'bottom: ' + noticeHeight() + 'px !important;');
      workPage.attr('style', 'margin-bottom: ' + noticeHeight() + 'px !important;');
    }

    resizeListeners.push(adjustBookingFloatingFooter);

    if (typeof zE !== 'undefined') {
      $('.js-v2-basket.js-basket').on('end-fetching-quote', adjustZendeskLauncher);
    }

    adjustBookingFloatingFooter();
  }
});
