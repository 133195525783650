import $ from 'jquery'

$(function () {
  const $body = $('body');
  window.Application.display_mobile_popup = function (modal) {
    $body.addClass('mobile-popup-open');
    modal.addClass('show');
  };
  window.Application.close_mobile_popup = function (modal) {
    $body.removeClass('mobile-popup-open');
    modal.removeClass('show');
  };
});
