!function ($) {
  $.fn.bindMechanicWorkItemsList = function (options) {
    const settings = $.extend({}, options);

    const hideAll = function (mechanicWorkItemsList) {
      mechanicWorkItemsList.find('.work-item-category-content').hide();
    }

    const toggleActive = function (clickedElement) {
      const row = clickedElement.parent();
      if (row.hasClass('active')) {
        row.removeClass('active').children('.work-item-category-content').addClass('folded').slideUp('500');
      } else {
        row.addClass('active').children('.work-item-category-content').removeClass('folded').slideDown('500');
      }
    }

    const uncheckAllChildWorkItems = function (workItemCategory) {
      workItemCategory.find('.custom-checkbox.custom-checkbox-checked:not(.check-all-checkbox)').each(function () {
        $(this).trigger('click');
      });
    }

    const checkAllChildWorkItems = function (workItemCategory) {
      workItemCategory.find('.custom-checkbox:not(.check-all-checkbox):not(.custom-checkbox-checked)').each(function () {
        $(this).trigger('click');
      });
    }

    const bindActions = function (mechanicWorkItemsList) {
      mechanicWorkItemsList.find('.work-item-category-header').each(function () {
        $(this).on('click', function () {
          toggleActive($(this));
        });
      });
    }

    const checkGroupCheckbox = function (box) {
      const itag = box.find('i');

      box.removeClass('undefined').addClass('checked');
      itag.removeClass('fa-minus').addClass('fa-check');
    }

    const uncheckGroupCheckbox = function (box) {
      const itag = box.find('i');

      box.removeClass('checked').removeClass('undefined');
      itag.removeClass('fa-minus').removeClass('fa-check');
    }

    const bindGroupCheckbox = function (checkbox) {
      checkbox.on('click', function () {
        const box = $(this);
        const workItemCategory = $(this).closest('.work-item-category');

        let workItemCategoryClass = '.check-all-checkbox';
        let selector = '.work-item';

        if (box.hasClass('clickmechanic-active-checkbox')) {
          workItemCategoryClass += '.clickmechanic-active-checkbox';
          selector = '.clickmechanic-work-item';
        }
        else if (box.hasClass('mechanic-active-checkbox')) {
          workItemCategoryClass += '.mechanic-active-checkbox';
          selector = '.mechanic-work-item';
        }

        if (box.hasClass('checked')) {
          uncheckGroupCheckbox(workItemCategory.find(workItemCategoryClass));
          uncheckAllChildWorkItems(workItemCategory.find(selector));
        } else {
          checkGroupCheckbox(workItemCategory.find(workItemCategoryClass));
          checkAllChildWorkItems(workItemCategory.find(selector));
        }
        return false;
      })
    }

    const setGroupCheckboxes = function (mechanicWorkItemsList) {
      mechanicWorkItemsList.find('.check-all-checkbox').each(function () {
        const workItemCategory = $(this).closest('.work-item-category');

        let selector = '';
        if ($(this).hasClass('clickmechanic-active-checkbox')) {
          selector = '.clickmechanic-work-item-checkbox';
        } else if ($(this).hasClass('mechanic-active-checkbox')) {
          selector = '.mechanic-work-item-checkbox';
        }

        const checked = workItemCategory.find('input' + selector + '[type=checkbox]:checked');
        const unchecked = workItemCategory.find('input' + selector + '[type=checkbox]:not(:checked)');

        if (checked.length > 0 && unchecked.length === 0) {
          $(this).addClass('checked').find('i').addClass('fa-check');
        }
        if (checked.length > 0 && unchecked.length > 0) {
          $(this).addClass('undefined').find('i').addClass('fa-minus');
        }

        bindGroupCheckbox($(this));
      });
    }

    return this.each(function () {
      const mechanicWorkItemsList = $(this);
      hideAll(mechanicWorkItemsList);

      setGroupCheckboxes(mechanicWorkItemsList);
      bindActions(mechanicWorkItemsList);
    });
  };
}(window.jQuery);
