import $ from 'jquery'

$(function () {
  const form = $('form#mir_form')
  if (form.length) { 
    form.on('success', logSubmitAndRedirect);

    function logSubmitAndRedirect(e, data) {
      const type = $(this).find("#mir_request_form_type")[0].value;
      _kmq.push(['record', 'Submitted MiR Form', {type: type}]);

      const MS_WAIT_FOR_KM_EVENT = 250;
      setTimeout(function() {
        window.location = data['redirect'];
      }, (MS_WAIT_FOR_KM_EVENT));
      return false;
    }
  }
});
