import $ from 'jquery'

$(function () {

  const crossSellContainer = $('.js-cross-sells-container');

  function updateQuote(url, workItemId, success, error) {
    $.ajax({
      method: 'POST',
      url: url,
      data: {
        work_item_id: workItemId
      },
      success: function (data) {
        success();
        crossSellContainer.trigger('quoteUpdated', data);
      },
      error: function () {
        error();
        crossSellContainer.trigger('quoteUpdateFailure');
      }
    });
  }

  function addCrossSell(workItemId, btn) {
    updateQuote('/bookings/add_item', workItemId,
      function () {
        updateCrossSellPrices();
        reenableButton(btn, 'Remove');
      },
      function () {
        reenableButton(btn, 'Add');
      });
  }

  function removeCrossSell(workItemId, btn) {
    updateQuote('/bookings/remove_item', workItemId,
      function () {
        updateCrossSellPrices();
        reenableButton(btn, 'Add');
      },
      function () {
        reenableButton(btn, 'Remove');
      });
  }

  function reenableButton(btn, enabledText) {
    btn.find('span').text(enabledText);
    btn.prop('disabled', false);
  }

  function disableButton(btn, disabledText) {
    btn.find('span').text(disabledText);
    btn.prop('disabled', true);
  }

  function updateCrossSellPrices() {
    hidePrices();
    showLoadingLabels();

    fetchCrossSellPrices((data) => {
      updatePrices(data);

      hideLoadingLabels();
      showPrices();
    }, () => {
      hideLoadingLabels();
      showPrices();
    });
  }

  function fetchCrossSellPrices(success, error) {
    $.ajax({
      method: 'GET',
      url: '/bookings/cross-sells.json',
      success: function (data) {
        success(data);
      },
      error: function () {
        error();
      }
    });
  }

  function showLoadingLabels() {
    crossSellContainer.find('.js-price-loading').removeClass('hidden');
  }

  function hideLoadingLabels() {
    crossSellContainer.find('.js-price-loading').addClass('hidden');
  }

  function showPrices() {
    crossSellContainer.find('.js-price').removeClass('hidden');
  }

  function hidePrices() {
    crossSellContainer.find('.js-price').addClass('hidden');
  }

  function updatePrices(data) {
    const crossSellList = crossSellContainer.find('li.js-with-prices');

    crossSellList.each(
      (index, element) => {
        const workItemId = $(element).data('id');
        const workItemData = data.filter(workItem => workItem.id == workItemId)[0];

        if (workItemData && workItemData['price']) {
          const newPrice = parseFloat(workItemData['price']).toFixed(2);
          $(element).find('.js-price').text(`£${newPrice}`);
        }
      }
    )
  }

  function sendKissmetricsEvent(workItemId, description, added) {
    const props = {
      id: workItemId,
      booking_id: bookingId,
      description: description,
      path: window.location.pathname,
      section: 'details.crossSell'
    };

    if (added) {
      _kmq.push(['record', 'Selected Work Item', props]);
    } else {
      _kmq.push(['record', 'Unselected Work Item', props]);
    }
  }

  crossSellContainer.on('click', '.js-add-work-item-btn', function () {
    const thisBtn = $(this)
    const workItemId = thisBtn.attr('data-id');

    const all_buttons = thisBtn.closest(".cross-sell-work-item")[0].querySelectorAll(".js-add-work-item-btn")

    all_buttons.forEach(function (btnElement) {
      const btn = $(btnElement);
      if (btn.hasClass('added')) {
        disableButton(btn, 'Removing');
        if (btn.prop('tagName') === "BUTTON") {
          btn.removeClass('button-red').addClass('button-blue')
        }
        btn.removeClass('added');
        removeCrossSell(workItemId, btn);
      } else {
        disableButton(btn, 'Adding');
        if (btn.prop('tagName') === "BUTTON") {
          btn.removeClass('button-blue').addClass('button-red')
        }
        btn.addClass('added');
        addCrossSell(workItemId, btn);
      }
    });

    const description = thisBtn.parents('li.cross-sell-item').data('description');
    sendKissmetricsEvent(workItemId, description, thisBtn.hasClass('added'));

    crossSellContainer.trigger('quoteUpdating');
  });

});
