import $ from 'jquery'

$(function () {
  (window.handle_mechanic_profile_form = function () {
    var form = $('form#mechanic-profile-form');
    var sameAsContactAddressCheckbox = form.find('#mechanic_contact_address_attributes_use_as_base');
    var premisesAddressForm = form.find('#premises-address-form');

    if (premisesAddressForm.hasClass('hidden')) {
      premisesAddressForm.find('input').prop('disabled', true);
    }

    sameAsContactAddressCheckbox.on('change', function () {
      if (this.checked && !premisesAddressForm.hasClass('hidden')) {
        premisesAddressForm.find('input').prop('disabled', true);
        premisesAddressForm.addClass('hidden');
      } else {
        premisesAddressForm.find('input').prop('disabled', false);
        premisesAddressForm.removeClass('hidden');
      }
    });
  })();
});
