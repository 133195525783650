import { Controller } from "stimulus";
import $ from "jquery";

class ScrollingBehavior {
  constructor(inner, container, leftButton, rightButton) {
    this.inner = inner;
    this.container = container;
    this.leftButton = leftButton;
    this.rightButton = rightButton;
  }

  scroll(amount, direction) {
    const currentScrollLeft = this.inner.scrollLeft();
    const maxScrollLeft = this.inner[0].scrollWidth - $(this.container).width();
    const remainingScroll = maxScrollLeft - currentScrollLeft;

    if ((direction === 'left' && currentScrollLeft > 0) ||
        (direction === 'right' && remainingScroll > 0)) {
      const scrollAmount = Math.min($(this.container).width(), amount);
      const scrollDirection = (direction === 'left') ? '-=' : '+=';
      this.inner.animate({ scrollLeft: scrollDirection + scrollAmount }, 500);
    } else {
      if (direction === 'left') {
        this.leftButton.addClass('disabled');
      } else {
        this.rightButton.addClass('disabled');
      }
    }
    this.updateButtonState();
  }

  updateButtonState() {
    const currentScrollLeft = this.inner.scrollLeft();
    const maxScrollLeft = this.inner[0].scrollWidth - $(this.container).width();
    const remainingScroll = maxScrollLeft - currentScrollLeft;

    if (currentScrollLeft === 0) {
      this.leftButton.addClass('disabled');
    } else {
      this.leftButton.removeClass('disabled');
    }

    if (remainingScroll <= 0) {
      this.rightButton.addClass('disabled');
    } else {
      this.rightButton.removeClass('disabled');
    }
  }
}

export default class extends Controller {
  static targets = [ "carouselContainer", "carouselInner", "leftButton", "rightButton" ];

  connect() {
    this.scrollingBehavior = new ScrollingBehavior(
        $(this.carouselInnerTarget),
        $(this.carouselContainerTarget),
        $(this.leftButtonTarget),
        $(this.rightButtonTarget)
    );

    this.setInitialButtonState();
    this.bindScrollEvent();
  }

  setInitialButtonState() {
    this.scrollingBehavior.updateButtonState();
  }

  bindScrollEvent() {
    $(this.carouselInnerTarget).on('scroll', () => {
      this.scrollingBehavior.updateButtonState();
    });
  }

  scrollLeft() {
    this.scrollingBehavior.scroll($(this.carouselContainerTarget).width(), 'left');
  }

  scrollRight() {
    this.scrollingBehavior.scroll($(this.carouselContainerTarget).width(), 'right');
  }
}
