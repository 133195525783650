import $ from 'jquery'
import $Form from './forms'

$(function () {
  var resolutionCase, updateSlideDown;
  if (typeof window.Application === 'undefined') {
    window.Application = {};
  }
  resolutionCase = $("#new_resolution_case, .edit_resolution_case");
  resolutionCase.on('change', "#resolution_case_issue_type", function (event) {
    var form;
    form = $(event.delegateTarget);
    form.attr('action', $(event.target).data('url'));
    $Form.postAjax(form, form.find('input[type=submit]'));
    return true
  });
  updateSlideDown = function ($target, content) {
    $target.html(content);
    if (content && content.trim()) {
      return $target.slideDown();
    }
  };
  window.Application.slideUpdate = function ($target, content) {
    if ($target.is(":visible")) {
      return $target.slideUp(function () {
        return updateSlideDown($target, content);
      });
    } else {
      return updateSlideDown($target, content);
    }
  };

  return resolutionCase.add('#new_resolution_case_message').on('click', '.remove-attachment', function (e) {
    var file_url;
    e.preventDefault();
    e.stopPropagation();
    // slice http:// from beginning
    file_url = $(e.target).prev('a').attr('href').slice(7);
    $(e.delegateTarget).find('input[value="' + file_url + '"]').remove();
    $(e.target).parent('li').remove();
    if ($('.uploaded-files').find('li').length === 0) {
      return $('.uploaded-files').addClass('hidden');
    }
  });
});
