import $ from 'jquery'
import $Form from '../common/forms'

$(function () {
  var form = $('form#new_mechanic_review');

  if (form.length) {

    var starRatings = form.find('ul.give-star-rating'),
        noOfRatings = starRatings.length,
        resolutionCentrePrompt = $('div#resolution-centre-prompt'),
        seenPrompt = false;

    // If any ratings are already on the page, add a class to them
    starRatings.find('input[type=radio]:checked').parent('li').addClass('checked');


    // Handle the star ratings on click
    form.on('click', 'ul.give-star-rating li', function (e) {
      e.preventDefault();
      e.stopPropagation();

      // Add a class to keep the other stars highlighted
      var li = $(this),
          ul = li.parent('ul');
      ul.find('li.checked').removeClass('checked');
      li.addClass('checked');


      // Check the input - we need to do it this way so the function isn't called multiple times
      // as the click bubbles down
      li.find('input[type=radio]').prop('checked', true).trigger('change');


      // If the user hasn't already seen the prompt, check if all the star ratings have been assigned
      if (!seenPrompt) {
        var completedRatings = 0,
            totalScore = 0;

        starRatings.each(function () {
          var starRating = $(this);
          if (starRating.find('input[type=radio]:checked').length) {
            totalScore = totalScore + parseInt(starRating.find('input[type=radio]:checked').val());
            completedRatings++;
          }
        });


        // If they have, and the mean score is low, ask the user if they'd rather open a resolution case
        // instead of a bad review
        if (completedRatings === noOfRatings) {
          var meanScore = Math.round(totalScore / noOfRatings);
          if (meanScore <= 2) {
            window.Application.display_modal(resolutionCentrePrompt);
            seenPrompt = true;
          }
        }
      }
    });

    addMinFieldLengthValidation(
        form,
        form.find('textarea[name="mechanic_review[comment]"]'),
        60,
        'We value your feedback on the mechanic, please add more detail.',
        $Form
    );

    function addMinFieldLengthValidation(form, field, minLength, errorMessage, $Form) {
      var fieldType = field.attr('type'),
          fieldName = field.attr('name');

      form.on('custom-validation', function (e, errors) {
        var fieldValue = field.val().trim();

        if (fieldValue.replace(/[^\w]|_/g, "").length < minLength) {
          errors++;
          $Form.showError(form, field, fieldType, fieldName, errorMessage);
        }
        form.trigger('custom-validation-callback', [errors]);
      });
    }

  }

});
