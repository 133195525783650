import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['unpriced', 'toggleButton', 'modelSelect', 'locationSelect'];

  toggle(event) {
    event.preventDefault();
    const $unpriced = $(this.unpricedTarget);
    if ($unpriced.is(':visible')) {
      $(this.toggleButtonTarget).text('Show ∨');
    } else {
      $(this.toggleButtonTarget).text('Hide ∧');
    }
    $unpriced.slideToggle();
  }

  updateEstimatesTable(event) {
    const modelInput = this.modelSelectTarget;
    const model = modelInput.value;
    const locationInput = this.locationSelectTarget;
    const location = locationInput.value;
    const targetUrl = `${modelInput.dataset.targetUrl}?model=${model}&location=${location}`;

    const frame = document.querySelector('turbo-frame#estimates-table-frame');
    frame.src = targetUrl;
  }
}
