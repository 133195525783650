import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["option"];

  selectOption() {
    const selectedAnswer = event.currentTarget.querySelector('.yes-or-no-answer');
    this.optionTargets.forEach(el => {
      el.classList.toggle("active", selectedAnswer === el);
    });
  }
}
