// We assume that the load order set by our HTML will ensure that the
// following are already loaded:
//     js_detection.js;
//     defer_until_loaded.js;
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import "@hotwired/turbo-rails"

Turbo.session.drive = false;

require('jquery');
require('mediaMatch');

import $Form from 'application/common/forms'

const rails = require('@rails/ujs');
rails.start();

require('cocoon');
require('fileUpload');
require('fileUploadProcess');
require('fileUploadValidation');
require('application/common/upload-files');
import lazyLoadComponents from 'application/lazy_load_components';
lazyLoadComponents();

require('@rails/activestorage').start();

// TODO: move what we can to NPM, rather than manually maintaining it here
// We should also make sure we're not pulling scripts into every page which don't need to be
// on every page
require('jquery.history');
require('jquery.smart-poll');
require('jquery-placeholder');
require('jquery.ba-throttle-debounce');


// TODO: convert these files to proper jQuery modules and remove as requires
require('application/common/accordions');
require('application/common/authentication.coffee');
require('application/common/bookings.coffee');
require('application/common/car_form.coffee');
require('application/common/careers.coffee');
require('application/common/cm_power100.coffee');
require('application/common/cookie_consent');
require('application/common/custom_checkbox');
require('application/common/date_time_picker');
require('application/common/direct_uploads');
require('application/common/dropdown.coffee');
require('application/common/facebook-pixel');
require('application/common/forms.coffee');
require('application/common/global.coffee');
require('application/common/google_maps');
require('application/common/information_prompt.coffee');
require('application/common/landing_pages.coffee');
require('application/common/landing_pages_view_more')
require('application/common/mechanics.coffee');
require('application/common/modal.coffee');
require('application/common/mobile_popup.coffee');
require('application/common/navbar.coffee');
require('application/common/floating_cta_button.coffee');
require('application/common/promo-code-form');
require('application/common/recaptcha');
require('application/common/resolution_cases.coffee');
require('application/common/scroll-to-anchor.coffee');
require('application/common/secondary-nav.coffee');
require('application/common/site.coffee');
require('application/common/tabbed-nav.coffee');
require('application/common/testimonials');
require('application/common/cross-sells');
require('application/common/utils');
require('application/common/zendesk_web_widget');


// Include pages
require('application/pages/availability/alternative');
require('application/pages/contact');
require('application/pages/work');
require('application/pages/billing');
require('application/pages/payment');
require('application/pages/booking-questions');
require('application/pages/bookings-complete');
require('application/pages/help');
require('application/pages/last-mechanic-form');
require('application/pages/mechanic-reviews');
require('application/pages/fleet-servicing');
require('application/pages/mechanic-application');
require('application/pages/mechanic-profile-form');
require('application/pages/mechanic-work-items');
require('application/pages/diagnostic-tool');
require('application/pages/mechanic-referrals');
require('application/pages/mir-form');
require('application/pages/mechanic-home');
require('application/pages/fulfilment_options');

require('controllers');

// Set-up
$(function () {
  $Form.setUpWatch();
});

require("trix")
require("@rails/actiontext")
