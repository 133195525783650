import $ from 'jquery'
import $Translate from '../i18n/translations'

$(function () {
  const form = $('form#new_mechanic_referral');

  if (form.length) {
    form.on('success', function (e, data) {

      const message = '<h3>' + $Translate.putOut('Thanks!') + '</h3>' +
        '<p>We’ll get in touch with your recommended mechanic soon, and let you know when they’ve activated their ' +
        'account and completed 5 jobs.</p>';

      form.siblings().addBack().slideUp();
      $(message).insertAfter(form).slideDown();
    });
  }
});
