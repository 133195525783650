import $ from 'jquery'

$(function () {
  if (document.location.hash !== '') {
    var id;
    $('.tab-pane.active').removeClass('active');
    id = document.location.hash.replace('#', '');
    $(document.getElementById(id)).addClass('active');
    $('li.is-selected').removeClass('is-selected');
    $('a[href$="' + document.location.hash + '"]').parent().addClass('is-selected');
  }

  return $('.secondary-nav.prevent-clicks a').on('click', function (e) {
    var li, tab_toggle, target;
    target = $(e.target);
    li = $(e.target.parentElement);
    tab_toggle = target.data('toggle') === 'tab';
    if (tab_toggle || li.hasClass('is-selected')) {
      e.preventDefault();
      document.location.hash = target.attr('href');
      target.closest('.secondary-nav').toggleClass('open');
      if (tab_toggle) {
        $('.tab-pane.active').removeClass('active');
        $(target.attr('href')).addClass('active');
        $('.secondary-nav li.is-selected').removeClass('is-selected');
        return li.addClass('is-selected');
      }
    }
  });
});
