import $ from 'jquery'
import $Translate from '../i18n/translations'

$(function () {
  // If the contact form exists, go into the code
  var form = $('form#new_fleet_enquiry');
  if (form.length) {

    form.on('success', function (e, data) {

      // Hide the form and show a success message
      var markUp = '<h3>' + $Translate.putOut('Thanks!') + '</h3>' +
          '<p>' + $Translate.putOut('We\'ll be in touch') + '</p>';

      form.siblings().addBack().slideUp();
      $(markUp).insertAfter(form).slideDown();

      // Google Code
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'NewFleetServicingEnquiry'
      });

    });

  }

});
