import $ from 'jquery'

// Handle tooltips
const $Tooltips = {

  watchTooltips: function (wrapper) {
    wrapper.on('click', '[data-tooltip-text]', function (e) {
      e.preventDefault();
      e.stopPropagation();

      var text = $(this),
          tooltip = text.next('.tooltip'),
          id = (typeof wrapper.attr('id') != 'undefined' ? wrapper.attr('id') : (Math.floor(Math.random() * 999) + 1));

      if (tooltip.hasClass('open')) {
        $Tooltips.closeTooltip(id, tooltip);
      } else {
        $Tooltips.openTooltip(id, tooltip);
      }

    });

  },


  openTooltip: function (id, tooltip) {
    tooltip.addClass('open');
    $Tooltips.addPageListeners(id, tooltip);
  },

  addPageListeners: function (id, tooltip) {
    $('body').on('click.tooltip_' + id, function (e) {
      var target = $(e.target);

      if (target != tooltip && (target.parents('.tooltip').length == 0 || target.parents('.tooltip').parents('.tooltip-wrapper').index() != tooltip.parents('.tooltip-wrapper').index())) {
        $Tooltips.closeTooltip(id, tooltip);
      }
    });
  },


  closeTooltip: function (id, tooltip) {
    tooltip.removeClass('open');
    $Tooltips.removePageListeners(id, tooltip);
  },

  removePageListeners: function (id, tooltip) {
    $('body').off('click.tooltip_' + id);
  }

};

export default $Tooltips;
