import $ from 'jquery'

!function ($) {
  const directUploadTemplate = window.document.getElementById('direct-upload-template');

  if (directUploadTemplate) {
    import('./templates').then(({default: Handlebars}) => {
      // dispatched for every file after form submission
      addEventListener('direct-upload:initialize', event => {
        const {target, detail} = event;
        const {id, file} = detail;
        const template = Handlebars.getTemplate('#direct-upload-template');
        const markUp = template({id: id, file_name: file.name});

        $(markUp).insertBefore($(target));
      });

      // form submitted
      addEventListener('direct-upload:start', event => {
        const {id} = event.detail;
        $('#direct-upload-' + id).removeClass('direct-upload--pending');
      });

      // progress of upload
      addEventListener('direct-upload:progress', event => {
        const {id, progress} = event.detail;
        $('#direct-upload-progress-' + id).width(progress + '%');
      });

      // upload throws an error
      addEventListener('direct-upload:error', event => {
        event.preventDefault();
        const {id, error} = event.detail;
        $('#direct-upload-' + id).addClass('direct-upload--error').attr('title', error);
      });

      // upload finished
      addEventListener('direct-upload:end', event => {
        const {id} = event.detail;
        $('#direct-upload-' + id).addClass('direct-upload--complete');
      });
    })
  }
}($);
