import $ from 'jquery'
import $Translate from '../i18n/translations'
import $Scroll from '../common/smooth-scrolling'

$(() => {
  const form = $('#new_contact'),
        emailUsButton = $('.js-email-us');

  if (form.length) {
    const contactUsPanel = $('div.contact-us-panel');

    form.on('success', (e, data) => {
      _kmq.push(['record', 'Submitted Contact Form']);
      const markUp = '<div class="alert alert-success">' +
              '<p class="title">' + $Translate.putOut('Contact message sent') + '</p>' +
              '<p>' + $Translate.putOut('Thank you for contacting us') + '</p>' +
              '</div>';

      $Scroll.smoothScroll(contactUsPanel);
      $(markUp).insertBefore(contactUsPanel);
      contactUsPanel.remove();
    });

    if (emailUsButton.length) {
      emailUsButton.on('click', () => {
        contactUsPanel.removeClass('hidden');
        $Scroll.smoothScroll(contactUsPanel);
      });
    }
  }
});
