var utils = (function () {
  function waitUntilTrue(predicate, callback, interval) {
    if (predicate() === true) {
      callback();
    } else {
      interval = (typeof (interval) === 'undefined' || isNaN(parseInt(interval, 10))) ? 50 : interval;
      window.setTimeout(function () {
        waitUntilTrue(predicate, callback, interval);
      }, interval);
    }
  }

  function synchronizer() {
    var callbacks = [],
        triggered = false;

    function addCallback(callback) {
      callbacks.push(callback);
    }

    function runCallbacks() {
      callbacks.forEach(function (callback) {
        callback();
      });
    }

    var trigger = function() {
      triggered = true;
      runCallbacks();
    };

    trigger.then = function(callback) {
      if (triggered) {
        callback();
      } else {
        addCallback(callback);
      }
    };

    return trigger;
  }


  return {
    waitUntilTrue: waitUntilTrue,
    synchronizer: synchronizer
  };
})();


module.exports = utils;
