import $ from 'jquery'

$(function () {
  var resize_timer, size_modal;
  resize_timer = 0;
  size_modal = function () {
    var modal, modal_content;
    modal = $('.modal.show');
    modal_content = modal.find('.modal-content');
    if (modal.css('left') === '0px') {
      return modal_content.css('max-height', $(window).height() - modal.find('.modal-close-container').height());
    } else {
      return modal_content.css('max-height', $(window).height() * 0.8);
    }
  };
  window.Application.close_modal = function (modal_to_close) {
    modal_to_close.removeClass('show');
    $('.backdrop').removeClass('modal-open');
    $('body').removeClass('show-backdrop');
    $('.backdrop').off('click.modal tap.modal');
    return $('.modal-close-btn, .js-close-modal').off('click.modal tap.modal');
  };
  window.Application.display_modal = function (modal_to_show) {
    var backdrop, modal_body;
    modal_to_show.addClass('show');
    backdrop = $('.backdrop');
    backdrop.addClass('modal-open');
    size_modal();
    $(window).on('resize.modal', function (e) {
      clearTimeout(resize_timer);
      return resize_timer = setTimeout(size_modal, 75);
    });
    $('body').addClass('show-backdrop');
    if (modal_to_show.data('modal-close') === false) {
      return backdrop.addClass('no-close');
    } else {
      $('.backdrop, .modal-close-btn, .js-close-modal').on('click.modal tap.modal', function (e) {
        return window.Application.close_modal(modal_to_show);
      });
      if (modal_to_show.hasClass('transparent')) {
        modal_body = modal_to_show.find('.modal-body');
        return modal_body.on('click.modal tap.modal', function (e) {
          if (modal_body.has(e.target).length === 0) {
            return window.Application.close_modal(modal_to_show);
          }
        });
      }
    }
  };
  return $('body').on('click', 'a[data-modal-id]', function (e) {
    var modal_to_show;
    e.preventDefault();
    const trigger = $(e.currentTarget);

    modal_to_show = $('#' + trigger.data('modalId'));
    if (trigger.data('modal-heading')) {
      modal_to_show.find('.modal-heading').html(trigger.data('modal-heading'));
    }
    if (trigger.data('href')) {
      modal_to_show.find('.loading-container').removeClass('hidden');
      modal_to_show.find('.loaded-content-container').addClass('hidden');
      $.get({
        url: $(e.currentTarget).data('href'),
        dataType: 'html',
        success: function (response, status, xhr) {
          modal_to_show.find('.loaded-content-container').html(response).removeClass('hidden');
          return modal_to_show.find('.loading-container').addClass('hidden');
        }
      });
    }
    if (trigger.data('active')) {
      const selector = modal_to_show.find(trigger.data('active'));
      selector.addClass('active');
      selector.siblings().not(selector).removeClass('active');
    }
    window.Application.display_modal(modal_to_show);
  });
});
