import $ from 'jquery'

$(function () {
  const onWorkPage = $('.v2-work-page-container').length > 0;
  if (onWorkPage) {
    import('./work/work.coffee');
    import('./work/v2_work');
    import('./tyres/tyres');
  }

  window.Application.adjustPageToFitHeader = function() {
    $('body.page-booking').css('padding-top', $('.fixed-header').height())
  }
  $(window).on('resize', window.Application.adjustPageToFitHeader).trigger('resize');
});
