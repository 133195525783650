import $ from 'jquery'

$(function () {

  const form = $('#alternative_availability_form');

  if (form.length) {

    // Disable submit button if no checkboxes checked
    const submit_button = form.find('input[type="submit"]')
    submit_button.prop('disabled', form.find('input[type=checkbox]:checked').length === 0);

    const checkboxes = $('.availability-checkbox');
    checkboxes.on('click', function () {
      const checked_checkboxes = checkboxes.find(':checked');
      submit_button.prop('disabled', checked_checkboxes.length === 0);
    });

    const confirmButton = form.find('.desktop-confirm');

    const updateMobileFooter = (() => {
      if($(window).scrollTop() + $(window).innerHeight() > confirmButton.offset().top) {
        $('.mobile-floating-footer').addClass('hidden');
      } else {
        $('.mobile-floating-footer').removeClass('hidden');
      }
    });

    $(window).on('scroll resize', function() {
      updateMobileFooter();
    });

    updateMobileFooter();
  }

});
