import $ from 'jquery'

$(function () {
  var update_mobile_cta;
  var enable_from = $('.mobile-cta').data('enable-from') || '#booking_car_form';

  update_mobile_cta = function () {
    if ($(document).scrollTop() > $(enable_from).offset()?.top + $(enable_from).height() - 50) {
      return $('.mobile-cta').addClass('show');
    } else {
      return $('.mobile-cta').removeClass('show');
    }
  };
  if ($('.mobile-cta').length) {
    update_mobile_cta();
    return $(window).on('scroll resize', function (e) {
      return update_mobile_cta();
    });
  }
});
