import $ from 'jquery'

$(function () {
  var hide;
  hide = function (elem) {
    elem.removeClass('show');
    return $('body').off('click.dropdown');
  };
  return $('body').on('click', '.dropdown', function (event) {
    var menu, toggleOrMenu;
    toggleOrMenu = $(event.target).closest('.dropdown, .dropdown-menu');
    if (toggleOrMenu.hasClass('dropdown')) {
      event.preventDefault();
      menu = toggleOrMenu.find('.dropdown-menu');
      if (menu.hasClass('show')) {
        return hide(menu);
      } else {
        menu.addClass('show');
        return $('body').on('click.dropdown', function (e) {
          if (toggleOrMenu.has(e.target).length === 0) {
            return hide(menu);
          }
        });
      }
    }
  });
});
