import $ from 'jquery'

$(function () {
  $(document).on('focusin', '.prompt-input', function (e) {
    var prompt;
    prompt = $(e.target).parent().find('.information-prompt');
    prompt.css('left', $(e.target).outerWidth() + 10);
    prompt.css('top', $(e.target).position().top);
    prompt.addClass('show');
    return $(window).on('resize.prompt', function () {
      var input;
      prompt = $('.information-prompt.show');
      input = prompt.parent().find('.prompt-input');
      prompt.css('left', input.outerWidth() + 10);
      return prompt.css('top', input.position().top);
    });
  });
  return $(document).on('focusout', '.prompt-input', function (e) {
    var prompt;
    prompt = $(e.target).parent().find('.information-prompt');
    prompt.removeClass('show');
    return $(window).off('resize.prompt');
  });
});
