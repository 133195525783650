import $ from 'jquery'
import $Form from './forms'

$(() => {
  const form = $('.recaptcha-form'),
      submitBtn = form.find('input[type=submit]');

  if (form.length) {

    form.on('custom-validation', function (e, noOfErrors) {
      if (noOfErrors === 0) {
        $Form.disableButton(submitBtn);
        grecaptcha.execute();
        $Form.renableButton(submitBtn);
      } else {
        form.trigger('custom-validation-callback', [noOfErrors]);
      }
    });

    window.submitInvisibleRecaptchaForm = function () {
      $Form.disableButton(submitBtn);
      $Form.submitForm(form, submitBtn);
    };
  }
});
