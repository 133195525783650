import $ from 'jquery'
import form from '../common/forms'

$(() => {
  const authenticationForm = $('#authentication-form');
  const submitButton = authenticationForm.find('input[type=submit]');

  if (authenticationForm.length) {
    const _stripePublishableKey = $('meta[name=stripe-publishable-key]').attr('content')
    const stripe = Stripe(_stripePublishableKey, {stripeAccount: submitButton.data('stripe-account')})

    $(authenticationForm).on('submit', authenticationFormSubmit);

    function authenticationFormSubmit(e) {
      e.preventDefault();
      e.stopPropagation();

      form.disableButton(submitButton);

      stripe
        .confirmCardPayment(submitButton.data('payment-intent-secret'), {
          payment_method: submitButton.data('payment-method'),
        })
        .then(function (result) {
          if (result.error) {
            form.showGeneralError(authenticationForm, 'stripe', result.error.message)
            form.renableButton(submitButton);
          } else {
            authenticationForm.find('input#payment_intent_id').val(result.paymentIntent.id);
            form.postAjax(authenticationForm, submitButton)
          }
        });
    }
  }
})
