import $ from 'jquery'
import $Translate from '../i18n/translations'
import $Scroll from '../common/smooth-scrolling'

$(function () {
  // If the contact form exists, go into the code
  var form = $('form.new_help_topic, form.edit_help_topic, form.new_help_article, form.edit_help_article');
  if (form.length) {

    form.on('success', function (e, data) {

      var content = $('div#site-container').children('.article-form'),
          markUp = '<div class="alert alert-info">' +
              '<p>' + $Translate.putOut(data['success']) + '</p>' +
              '</div>';

      $Scroll.smoothScroll(content);
      $(markUp).insertBefore(content);

      if (data['redirect']) {
        setTimeout(function () {
          window.location = data['redirect'];
        }, 500);
      } else {
        setTimeout(function () {
          content.prev('div.alert:last').remove();
        }, 1500);
      }

    });

  }

});
