// initialise function find-more-mechanics button and run this on the landing page

const moreMechanicsButton = document.querySelector('.js-more-mechanics-button');

if (moreMechanicsButton) {
  const reviewGroups = document.querySelectorAll('[id^=review-group-]');
  const reviewsDisplayed = 15
  const additional_reviews_per_page = 5

  let count = reviewsDisplayed/additional_reviews_per_page;

  moreMechanicsButton.addEventListener('click', () => {
    if (count < reviewGroups.length) {
      reviewGroups[count].classList.remove('hidden');
      count++;

      if (count === reviewGroups.length) {
        moreMechanicsButton.classList.add('hidden');
      }
    }
  });
}
