import $ from 'jquery'

$(function () {
  return $(".scroll-to-anchor").on("click", function (event) {
    var anchorSelector, link, scrollPos;
    link = $(event.delegateTarget);
    anchorSelector = link.data("scroll-target");
    if (!anchorSelector) {
      anchorSelector = link.attr("href");
    }
    scrollPos = $(anchorSelector).offset().top - 72; // 56px (navbar height) + 16px (base size margin)
    $('html, body').animate({
      scrollTop: scrollPos
    });
    return false;
  });
});
