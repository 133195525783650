import $ from 'jquery'

$(function () {
  return $("#new_careers_email_form").on("change", "#file-picker", function (event) {
    var file_picker, file_size, max_size_in_bytes;
    file_size = event.target.files[0].size;
    max_size_in_bytes = 5242880;
    if (file_size > max_size_in_bytes) {
      file_picker = $(event.target);
      alert('File is too big, it must be under 5MB in size');
      return file_picker.replaceWith(file_picker.clone());
    }
  });
});
