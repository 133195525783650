import jquery from 'jquery'
window.jQuery = jquery

!function ($) {
  $.fn.bindCheckBox = function (options) {

    var settings = $.extend({
      groupSelector: "",
      checkboxSelector: "input[type=checkbox]",
      labelSelector: "label",
      checkMarker: "<i class='fa fa-check'></i>",
      checkMarkerStyle: {'font-size': '20px'},
      checkboxDescriptionStyle: {},
      style: {'cursor': 'pointer', 'display': 'flex', 'align-items': 'center'},
      disabledStyle: {'display': 'flex', 'align-items': 'center'}
    }, options);

    var setVisibility = function (object, visible) {
      if (visible) {
        object.removeClass('hidden');
      } else {
        object.addClass('hidden');
      }
    };

    var toggleChecked = function (wrapper, group, checkbox) {
      if (wrapper.hasClass('custom-checkbox-disabled')) {
        return;
      }

      if (checkbox.is(':checked')) {
        checkbox.prop('checked', false);
        wrapper.removeClass('custom-checkbox-checked');
        checkbox.trigger('unchecked');
      } else {
        checkbox.prop('checked', true);
        wrapper.addClass('custom-checkbox-checked');

        if (group) {
          group.find(settings.checkboxSelector).not(checkbox).prop('checked', false);
          group.find('div').not(wrapper).removeClass('custom-checkbox-checked');
        }
        checkbox.trigger('checked');
      }
    };

    var bindActions = function (wrapper, group, formCheckbox) {
      wrapper.on('click', function () {
        toggleChecked(wrapper, group, formCheckbox);
      });
    };

    var createCheckboxDiv = function (div, group, checkbox, label, isChecked, isDisabled) {
      var wrapper = $("<div />")
          .addClass('custom-checkbox noselect');

      if (isChecked) {
        wrapper.addClass('custom-checkbox-checked');
      }
      if (isDisabled) {
        wrapper.addClass('custom-checkbox-disabled');
        wrapper.css(settings.disabledStyle);
      } else {
        wrapper.css(settings.style);
      }

      var checkMarker = $("<div />")
          .addClass('check-marker')
          .css(settings.checkMarkerStyle)
          .html(settings.checkMarker);
      var description = $("<div />")
          .addClass('checkbox-description')
          .css(settings.checkboxDescriptionStyle)
          .text(label.text());

      checkMarker.appendTo(wrapper);
      description.appendTo(wrapper);

      bindActions(wrapper, group, checkbox);

      wrapper.appendTo(div);
    };

    return this.each(function () {
      var div = $(this);
      // html elements
      var group = div.closest(settings.groupSelector);
      var checkbox = div.find(settings.checkboxSelector);
      var label = div.find(settings.labelSelector);
      // boolean values
      var isChecked = checkbox.is(':checked');
      var isDisabled = checkbox.prop('disabled');

      setVisibility(checkbox, false);
      setVisibility(label, false);

      createCheckboxDiv(div, group, checkbox, label, isChecked, isDisabled);
    });

  };
}(window.jQuery);
