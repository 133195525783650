import $ from 'jquery'

$(function () {
  window.Application.FacebookPixel = {};

  window.Application.FacebookPixel.add_to_cart = function () {
    if (window.fbq === undefined) {
      return;
    }


    var $button = $(this);
    var data = $(this).data();

    if (!$button.hasClass('added') && data.workCategory !== '') {

      fbq('trackCustom', 'AddToCart', {
        content_name: data.workDescription,
        content_category: data.workCategory,
        content_ids: [data.id],
        content_type: 'product',
        number_of_items: 1
      });
    }
  };

  $('.js-add-work-item-btn').on('click tap', window.Application.FacebookPixel.add_to_cart);
});
