import $ from 'jquery'
import $Form from '../common/forms'
import $Translate from '../i18n/translations'

$(function () {
  // If the heard about us form exists, go into the code
  var form = $('form#heard-about-us-form');
  if (form.length) {

    // Watch the checkboxes to hide or show textareas
    var checkboxes = form.find('input[type=checkbox]');

    checkboxes.on('change', function (e) {
      var input = $(this),
          textField = input.parent().find('input[type=text]');

      if (textField.length) {
        if (input.is(':checked')) {
          textField.slideDown(200);
        } else {
          textField.hide();
        }
      }

    });


    // Ensure at least one checkbox is checked
    form.on('custom-validation', function (e, noOfErrors) {
          var checkedCheckboxes = checkboxes.filter(':checked');

          if (checkedCheckboxes.length == 0) {
            var firstCheckbox = checkboxes.filter(':first');

            $Form.showError(form, firstCheckbox, 'checkbox', firstCheckbox.attr('name'), $Translate.putOut('Please choose at least one option'));
            $Form.setErrorListeners(checkboxes, 'checkbox', $Form.getErrorID(firstCheckbox));

            form.trigger('custom-validation-callback', [noOfErrors + 1]);
          } else {
            var errors = noOfErrors;

            checkedCheckboxes.each(function () {
              var input = $(this),
                  textField = input.parent().find('input[type=text]');

              if (textField.length && textField.val().trim().length == 0) {
                var name = input.attr('data-name'),
                    error = 'Please specify';
                switch (name) {
                  case 'Other':
                    error = 'Please tell us where the other place you heard about us was';
                    break;

                  case 'On another website':
                    error = 'What was the other website you heard about us from?';
                    break;
                }


                errors++;
                $Form.showError(form, input, input.attr('type'), input.attr('name'), $Translate.putOut(error));
                $Form.setErrorListeners(textField, textField.attr('type'), $Form.getErrorID(input));
              }

            });


            form.trigger('custom-validation-callback', [errors]);
          }

        })


        // Show a success message
        .on('success', function (e, data) {

          // Hide the form and show a success message
          var markUp = '<div class="alert alert-success">' +
              '<p>' + $Translate.putOut('Thanks for letting us know!') + '</p>' +
              '</div>';

          form.siblings().addBack().slideUp(200);
          setTimeout(function () {
            $(markUp).insertAfter(form).slideDown(50);
          }, 250);

        });


  }

});

