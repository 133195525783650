import { Controller } from "@hotwired/stimulus";
import $ from 'jquery';
import closeBasket from '../application/pages/work/close-basket.js';
import isDesktopScreen from "../application/helpers/is_desktop_screen";

const REVEAL_THRESHOLD_OFFSET = 30;
export default class extends Controller {
    static targets = ['totals', 'footer', 'quote', 'revealBasket', 'workPageRight', 'selectedWork'];

    connect() {
        $(document).on("end-fetching-quote", () => {
            this.handleBasket();
            this.showSelectedWork();
        });
        this.handleBasket();
    }

    fixedHeaderHeight() {
        return $('.fixed-header').outerHeight();
    }

    isThereItems() {
        return $('.v2-basket-selected-work li').length !== 0;
    }

    isMechanicMobileAbsent() {
        return $('.chosen-mechanic-mobile, .last-mechanic-mobile').length === 0;
    }

    addRevealBasketListener() {
        const revealThreshold = this.footerTarget.getBoundingClientRect().bottom;
        const shouldRevealBasket = window.scrollY >= 10 && window.scrollY + this.fixedHeaderHeight() > revealThreshold && this.isThereItems() && isDesktopScreen() && this.isMechanicMobileAbsent();

        this.revealBasketTarget.classList.toggle('hidden', !shouldRevealBasket);
    }

    revealBasket() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    handleBasket() {
        if (isDesktopScreen() && this.isMechanicMobileAbsent() && this.isThereItems()) {
            this.setBasket();
            this.setMarginTop();
        } else {
            this.resetBasket();
            this.quoteTarget.style.marginTop = '0px';
        }
    }

    setMarginTop() {
        this.quoteTarget.style.marginTop = this.totalsTarget.offsetHeight - this.revealBasketTarget.offsetHeight + 'px';
    }

    setBasket() {
        this.workPageRightTarget.classList.add('work-page-right-sticky');
        this.totalsTarget.style.top = `${this.fixedHeaderHeight() + Math.max(0, REVEAL_THRESHOLD_OFFSET - window.scrollY)}px`;
        this.addRevealBasketListener();
    }

    resetBasket() {
        this.workPageRightTarget.classList.remove('work-page-right-sticky');
        this.totalsTarget.style.top = '0px';
        this.revealBasketTarget.classList.remove('hidden');
        this.addRevealBasketListener();
    }

    closeTheModal() {
        if (isDesktopScreen()) {
            closeBasket();
        }
    }

    showSelectedWork() {
        this.selectedWorkTarget.classList.toggle('hidden', !this.isThereItems());
    }
}
