$("#booking_vehicle_drivable_false").click(function () {
    if ($(this).is(":checked")) {
        $("#recovery-message").slideDown().removeClass("hidden");
    }
});
$("#booking_vehicle_drivable_true").click(function () {
    if ($(this).is(":checked")) {
        $("#recovery-message").slideUp();
    }
});

const collection_field = $('#booking_mobile_or_collection_fee_true');

$("input[name='booking[mobile_or_collection_fee]']").on('change', function () {
    if (collection_field.is(':checked')) {
        $('#safe-to-drive-question').slideDown();
    } else {
        $('#safe-to-drive-question').slideUp();
    }
});
