import { Controller } from "stimulus"
import confetti from "canvas-confetti"

export default class extends Controller {
  connect() {
    confetti({
      particleCount: 120,
      startVelocity: 20,
      spread: 500,
      origin: {
        y: 0.2,
      }
    });
  }
}
