import scroll from '../common/smooth-scrolling'

$(function () {
  const button = $('.js-mechanic-join-cta')

  if (button.length) {
    button.on('click', () => {
      const input = $('#mechanic_application_first_name')
      scroll.smoothScroll(input)
      input.focus()
    })
  }
})
