import $Config from './config'

// Handle i18n text in javascript
const $Translate = {

  // Put out the text
  putOut: function (s) {

    // If a translation is available, use it
    if (typeof s != 'undefined' && typeof $Config.i18n != 'undefined' && $Config.i18n[s]) {
      var subs = $Config.i18n[s];

      // Strong and span tags
      subs = subs.replace(/\[strong\]([^/]+)\[\/strong\]/g, '<strong>$1</strong>');
      subs = subs.replace(/\[span\]([^/]+)\[\/span\]/g, '<span>$1</span>');

      // Links
      subs = subs.replace(/\[link\="([^"]*)"([^\]]*)\](.*?)\[\/link\]/g, '<a href="$1"$2>$3</a>');

      // Swap any additional arguments for the value that was passed in
      // This will find [$1], [$2], etc., and replace them with additional arguments sent to putOut()
      // in the order they are passed in
      for (var i = 1, noOfArgs = arguments.length; i < noOfArgs; i++) {
        subs = subs.replace(new RegExp('\\[\\$' + i + '\\]', 'g'), arguments[i]);
      }

      return subs;
    }

    // Otherwise, put out the string that was passed in
    return s;
  },


  // Format numbers
  formatNumbers: function (n, noDecimal) {
    if (typeof n == 'number' && (typeof noDecimal == 'undefined' || noDecimal === false)) {
      n = Math.abs(n).toFixed(2);
    }

    return n.toString().replace(/./g, function (c, i, a) {
      return i && c !== '.' && !((a.length - i) % 3) ? config.i18n.thousandsSeparator + c : c;
    });
  }

};

export default $Translate;
