const getGaClientId = callback => {
  let clientId = null;
  if (typeof gtag !== 'undefined') {
    gtag('get', process.env.GA_APPLICATION_ID, 'client_id', (clientId) => {
      if (callback) {
        return callback(clientId)
      }
    })
  }
  return clientId;
}

export default getGaClientId;
