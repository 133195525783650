// Bring in languages
import enGB from './text/en-gb'

// TODO: set this up to allow for more languages
const $Config = {
  i18n: enGB
};


// Return $Config
export default $Config;
