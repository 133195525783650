import $ from 'jquery'
import getGaClientId from '../common/get_ga_client_id'

$(function () {
  const heardAboutUsSelect = $('select#heard-about-us-options');

  if (heardAboutUsSelect.length) {
    heardAboutUsSelect.on('change', function (e) {
      var selected_text = $(e.target).find('option:selected').html();
      if (/\Aother\z/i.test(selected_text)) {
        $('input#heard-about-us-text').slideDown(200);
      } else {
        $('input#heard-about-us-text').hide();
      }
    });
  }

  $('.js-mechanic-application-certification-checkbox input[type="checkbox"]').on('change', function(event) {
    const checkbox = $(event.target);
    const other_field = checkbox.closest('.js-mechanic-application-certification-checkbox').next();

    if (other_field.hasClass('js-mechanic-application-certification-other')) {
      if (checkbox.is(':checked')) {
        other_field.removeClass('hidden');
      } else {
        other_field.addClass('hidden');
        other_field.find('strong.error').remove();
      }
    }
  });

  deferUntilLoaded.script(function() {
    const addGaTracking = function (gaClientId) {
      $('#mechanic_application_form').each(function () {
        $(this).prepend('<input type="hidden" name="mechanic_application[ga_client_id]" value="' + gaClientId + '" />');
      });
    }
    getGaClientId(addGaTracking);
  })

  const button = $('#mechanic_application_form input[type="submit"]');
  if (button.length) {
    const text = button.data('disable-with');

    button.click(() => {
      button.val(text);
    })
  }
});
