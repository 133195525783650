import $ from 'jquery'
import Rails from '@rails/ujs';

$(function () {
  var resetRebookMechanicButtons = function () {
    var $buttons = $('.js-rebook-mechanic-btn');
    var checkboxChecked = $('.last-mechanic form :checkbox').prop('checked');
    var requestedMechanicId = $('#booking_requested_mechanic_id').val();

    $buttons.text('Book');

    if (requestedMechanicId !== undefined && checkboxChecked) {
      var $button = $('.js-rebook-mechanic-btn[data-id="' + requestedMechanicId + '"]');

      $button.text('Booked');
    }
  };

  $('body').on('change', '.last-mechanic form :checkbox', function () {
    var $checkbox = $(this);
    var $form = $checkbox.closest('form');
    var $forms = $('.last-mechanic form');

    $forms.find(':checkbox').prop('checked', $checkbox.prop('checked'));
    Rails.fire($form[0], 'submit')
    resetRebookMechanicButtons();

    if ($checkbox.prop('checked')) {
      var requestedMechanicPublicId = $form.attr('data-public-id');

      _kmq.push(['record', 'RebookingOptionChosen', {
        booking_id: bookingId,
        mechanic_id: requestedMechanicPublicId
      }]);
    }
  });

  $('.js-rebook-mechanic-btn').on('click tap', function () {
    var $button = $(this);
    var $forms = $('.last-mechanic form');
    var $partials = $('.last-mechanic');
    var requestedMechanicId = $(this).data('id');
    var requestedMechanicPublicId = $(this).data('public-id');

    window.Application.close_modal($('#previous-mechanics-modal'));

    $partials.find('.mechanic-name').text($(this).data('mechanic-name'));
    $partials.find('.mechanic-first-name').text($(this).data('mechanic-first-name'));
    $forms.find('#booking_requested_mechanic_id').val(requestedMechanicId);
    $forms.attr('data-public-id', requestedMechanicPublicId);

    if ($button.text() === 'Book') {
      $forms.find(':checkbox').prop('checked', true);
    } else {
      $forms.find(':checkbox').prop('checked', false);
    }
    $forms.find(':checkbox').first().trigger('change');
  });

  resetRebookMechanicButtons();
});
