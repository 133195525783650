import $ from 'jquery'

$(function () {
  var $accordions = $('.js-accordion');

  $accordions.filter('.accordion--state-active').find('.slide_down, .slide_up, .accordion_content').toggle();

  $accordions.children('.accordion_toggle').on('click', function () {
    var $this = $(this);
    var $accordion = $this.closest('.accordion'); // Closest finds closest parent with passed selector
    var $content = $accordion.children('.accordion_content');
    var $group = $accordion.data('accordion-group');
    var speed = $accordion.data('accordion-speed') === undefined ? 400 : $accordion.data('accordion-speed');

    $this.children('.slide_down, .slide_up').toggle();

    if ($accordion.hasClass('accordion--state-active')) {
      $content.slideUp(speed, function () {
        $accordion.removeClass('accordion--state-active');
      });
    } else {
      $accordion.addClass('accordion--state-active');
      $content.slideDown(speed);
      if($group) {
        $('.accordion[data-accordion-group=' + $group + '].accordion--state-active').not($accordion).each(function () {
          var accordion = $(this);
          accordion.children('.accordion_toggle').children('.slide_down, .slide_up').toggle();
          accordion.children('.accordion_content').slideUp(speed, function () {
            accordion.removeClass('accordion--state-active');
          });
        })
      }
    }
  });
});
